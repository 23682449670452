/******************************************************************************\
* File: SaveButton.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import { saveRide, canSaveRide, showSaveModal } from '@/store/edit_ride';
import { storeLastUpdatedRideOnCache } from '@/helpers/functions';
import Button from '@/components/common/Button';
import { translate } from '@/helpers/i18n';
const t = translate('mapListView.SaveButton');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const SaveButton = (props) => {
    const {
        saveRide,
        meta: { persisted, saving, saveModal },
        ride,
        showSaveModal,
        padded,
        widthAuto,
        canSaveRide,
        id
    } = props;

    const style = {
        minWidth: 122,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '12px',
        overflow: 'hidden'
    };
    if (padded) style.padding = 24;
    if (!!widthAuto) style.width = 'auto';
    const handleOnClick = () => {
        //if disabled do nothing
        if (!canSaveRide || saveModal) {
            return;
        }
        // save if ride has two waypoints or locations
        // if no name show modal before saving
        // else do nothing
        const { waypoints, locationHistory, name } = ride;
        if (
            (!!waypoints && waypoints.length > 1) ||
            (!!locationHistory && locationHistory.length > 1)
        ) {
            if (!!ride && (name || '').length > 0) {
                storeLastUpdatedRideOnCache(ride);
                return saveRide();
            } else {
                return showSaveModal(true, ride);
            }
        } else {
            return;
        }
    };

    return (
        <Button
            primary
            onClick={handleOnClick}
            disabled={!canSaveRide || saveModal}
            success={persisted && !saving}
            style={style}
            id={id ? id : undefined}
            data-testid={id ? id : undefined}>
            {saving
                ? t('Saving...')
                : canSaveRide || !persisted
                  ? t('Save Ride')
                  : t('Saved')}
        </Button>
    );
};
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        canSaveRide: canSaveRide(state),
        ride: state.edit_ride.present.ride,
        meta: state.edit_ride.present.meta
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showSaveModal: (value, reason) =>
            dispatch(showSaveModal(value, reason)),
        saveRide: () => dispatch(saveRide())
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(SaveButton);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
