/******************************************************************************\
 * File: Item.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import Text from '@/components/common/form/Text';
import Downshift from '@/components/common/search/Downshift';
import style from '@/style/mapListView/directions/Item.scss';
//------------------------------------------------------------------------------
// React Function Component Item -----------------------------------------------
const Item = (props) => {
    const {
        className,
        innerClassName,
        onClick,
        name,
        nameProps,
        largeIcon,
        length,
        icon,
        right,
        innerRef,
        editable,
        children,
        highlight,
        label,
        labelProps,
        print,
        isFirst,
        isLast,
        ...rest
    } = props;

    const cn = classNames(style.Item, {
        [className]: !!className,
        [style['clickable']]: !!onClick,
        [style['highlight']]: !!highlight,
        [style['large-icon']]: !!largeIcon
    });

    const NameComponent = editable ? Downshift : Text;
    const formattedName =
        typeof name === 'string' ? name.replace('\n', ' ') : name;

    return (
        <div className={cn} ref={innerRef} onClick={onClick} {...rest}>
            <div className={style.row}>
                {icon && <div className={style.icon}>{icon}</div>}
                <div
                    className={classNames(style.row, {
                        [style['inner']]: !print,
                        [innerClassName]: !!innerClassName
                    })}>
                    <div className={style.stackedNames}>
                        {label !== null &&
                            label !== undefined &&
                            label !== 'My Current Location' && (
                                <Text
                                    id={
                                        isFirst
                                            ? 'start-text'
                                            : isLast
                                              ? 'end-text'
                                              : undefined
                                    }
                                    data-testid={
                                        isFirst
                                            ? 'start-text'
                                            : isLast
                                              ? 'end-text'
                                              : undefined
                                    }
                                    className={classNames(
                                        style.name,
                                        style.label
                                    )}
                                    {...labelProps}
                                    value={label}
                                />
                            )}
                        <NameComponent
                            className={style.name}
                            {...nameProps}
                            value={formattedName}
                        />
                    </div>
                    {right}
                </div>
            </div>
            {children}
        </div>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Item;
