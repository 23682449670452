/******************************************************************************\
 * File: RenderRide.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Marker from '@/components/common/map/Marker';
import Line from '@/components/common/map/Line';
import TrafficLine from '@/components/common/map/TrafficLine';
import Waypoint from '@/components/common/map/icons/Waypoint';
import RouteMarker from '@/components/common/map/icons/RouteMarker';
import RouteEnd from '@/components/common/map/icons/RouteEnd';
import RouteStartFlag from '@/components/common/map/icons/RouteStartFlag';
import RouteGasStationMarker from '@/components/common/map/icons/RouteGasStationMarker';
import RouteChargingStationMarker from '@/components/common/map/icons/RouteChargingStationMarker';
import RouteRestaurantMarker from '@/components/common/map/icons/RouteRestaurantMarker';
import RouteHotelMarker from '@/components/common/map/icons/RouteHotelMarker';
import RouteScenicMarker from '@/components/common/map/icons/RouteScenicMarker';
import RouteDealerMarker from '@/components/common/map/icons/RouteDealerMarker';
import RouteEventMarker from '@/components/common/map/icons/RouteEventMarker';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { getMarkerIconType, getMarkerNumber } from '@/helpers/markers';
import { quadBezierBetweenPoints, dottedArray } from '@/helpers/math';
import { pointDistance } from '@/helpers/map';
import {
    WaypointCategory,
    WaypointType,
    RideSubtype
} from '@/helpers/constants';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Render Ride', false);
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
export const Waypoints = ({
    descriptor = { hide: false },
    highlightId,
    selectedId,
    enumerateWaypointIcons,
    offRoad,
    isDirty,
    waypoints,
    waypointProps,
    isLoop,
    rideSubType
}) => {
    const markers = {
        WAYPOINT: Waypoint,
        GAS_STATION: RouteGasStationMarker,
        CHARGING_STATION: RouteChargingStationMarker,
        RESTAURANT: RouteRestaurantMarker,
        SCENIC: RouteScenicMarker,
        HOTEL: RouteHotelMarker,
        EVENT: RouteEventMarker,
        DEALER: RouteDealerMarker,
        START: RouteStartFlag,
        END: RouteEnd,
        NUMBER: RouteMarker
    };

    const getRouteMarkerComponent = (
        point,
        i,
        waypoints,
        enumerateWaypointIcons,
        offRoad,
        isDirty
    ) => {
        // add isDirty / meta.dirty
        const enumerate = enumerateWaypointIcons && i > 0;
        const isWaypoint = point.type === 'WAYPOINT';
        // Show End flag if ride is not off road or is off road and is saved (not dirty)
        const type = getMarkerIconType(point, i, waypoints, offRoad, isDirty);
        const alwaysUseIcon = [
            'GAS_STATION',
            'CHARGING_STATION',
            'RESTAURANT',
            'SCENIC',
            'HOTEL',
            'EVENT',
            'DEALER',
            'START',
            'POI'
        ];

        const shouldDisplayType = alwaysUseIcon.indexOf(type) > -1;
        const shouldEnumerate =
            isDirty && enumerate && !isWaypoint && !shouldDisplayType;
        return isWaypoint
            ? markers.WAYPOINT
            : !shouldEnumerate
              ? markers[type]
              : markers.NUMBER;
    };

    const removeLastIfLoop = (waypoints, isLoop) => {
        return isLoop ? waypoints.slice(0, waypoints.length - 1) : waypoints;
    };

    const maybeHide = (waypoints, hide) => {
        return hide
            ? waypoints.filter((w) => w.type !== WaypointType.WAYPOINT)
            : waypoints;
    };

    const isRecorded = rideSubType === RideSubtype.RECORDED;
    return maybeHide(
        removeLastIfLoop(waypoints || [], isLoop && isRecorded),
        descriptor.hide
    ).map((point, i, points) => (
        <Marker
            key={i}
            data={{
                markerType: 'waypoint',
                ...point.data,
                ...point
            }}
            {...(typeof waypointProps === 'function'
                ? waypointProps(point, i, points)
                : waypointProps || {})}
            position={point}
            component={
                selectedId && point.id === selectedId
                    ? Waypoint
                    : getRouteMarkerComponent(
                          point,
                          i,
                          points,
                          enumerateWaypointIcons,
                          offRoad,
                          isDirty
                      )
            }
            componentProps={{
                isOnRoute: true,
                highlight:
                    typeof highlightId !== 'undefined' &&
                    highlightId === point.id,
                children: getMarkerNumber(point, i, points)
            }}
        />
    ));
};

export const Path = ({
    path,
    descriptor = { traffic: false },
    zIndex = 4,
    style = { lineWidth: 6, strokeColor: '#FF6600' },
    waypoints,
    ride,
    ...rest
}) => {
    const { offRoad, sections } = ride;
    const pts =
        !!path && (path || []).length > 1
            ? path
            : (waypoints || []).length > 1
              ? waypoints
              : [];
    return descriptor.traffic ? (
        <TrafficLine
            traffic={!offRoad ? path || [] : pts}
            sections={sections}
            {...rest}
        />
    ) : (
        <Line
            points={!offRoad ? path || [] : pts}
            sections={sections}
            {...rest}
        />
    );
};

const DashedLines = ({ ride, map, dealers }) => {
    const { waypoints, sections } = ride;

    if (!dealers || !waypoints) {
        return null;
    }

    const dashedLineWaypoints = (waypoints || []).filter(
        (waypoint) => waypoint.category === WaypointCategory.DEALER
    );

    const linesArray = dashedLineWaypoints.map((waypoint) => {
        const dealer = dealers.find(
            (dealer) => dealer.dealerId === waypoint.dealerId
        );

        if (!dealer) return [];

        const startPoint = dealer.position;
        const endPoint = waypoint;

        const line = quadBezierBetweenPoints(startPoint, endPoint, 1, 0.5, 32);
        const dist = pointDistance(startPoint, endPoint);
        const scale = map.zoom;
        return dottedArray(line, 50 / scale, 20 / scale);
    });

    return linesArray.map((resultLines) =>
        resultLines
            .filter((line) => line.length > 1)
            .map((line, i) => (
                <Line
                    key={i}
                    points={line}
                    sections={sections}
                    style={{ strokeColor: '#B5B5B5', lineWidth: 5 }}
                />
            ))
    );
};

const RenderRide = ({
    ride,
    isDirty,
    selectedId,
    highlightId,
    dashedLinesProps,
    enumerateWaypointIcons,
    pathProps,
    waypointProps,
    descriptor = { path: true, waypoints: true, dashedLines: true }
}) => {
    const { waypoints, points, isLoop, traffic, offRoad } = ride || {};
    return (
        <div>
            {descriptor.waypoints && (
                <Waypoints
                    ride={ride}
                    enumerateWaypointIcons={enumerateWaypointIcons}
                    descriptor={descriptor.waypoints}
                    isLoop={isLoop}
                    waypoints={waypoints}
                    points={points}
                    selectedId={selectedId}
                    highlightId={highlightId}
                    offRoad={offRoad}
                    isDirty={isDirty}
                    waypointProps={waypointProps}
                    rideSubType={ride.subType}
                />
            )}
            {descriptor.path && (
                <Path
                    path={
                        ride.subType !== RideSubtype.RECORDED &&
                        descriptor.path &&
                        descriptor.path.traffic
                            ? traffic
                            : points
                    }
                    ride={ride}
                    waypoints={waypoints}
                    {...pathProps}
                />
            )}
            {descriptor.dashedLines && (
                <DashedLines
                    descriptor={descriptor.dashedLines}
                    ride={ride}
                    {...dashedLinesProps}
                />
            )}
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RenderRide;
