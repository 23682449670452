export const removeOsanoWidgetIcon = (component) => {
    return setInterval(() => {
        const osanoWidget = document.querySelector('.osano-cm-widget');
        if (osanoWidget !== null) {
            osanoWidget.style.display = location.pathname.includes('map')
                ? 'none'
                : 'block';
            clearInterval(component.intervalo);
        }
    }, 100);
};

export const undoOsanoWidgetRemoval = () => {
    const osanoWidget = document.querySelector('.osano-cm-widget');
    if (osanoWidget !== null) osanoWidget.style.display = 'block';
};
