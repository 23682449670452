/******************************************************************************\
 * File: Dropdown.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/directions/Dropdown.scss';
import appStyle from '@/style/App.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import ArrowLink from '@/components/common/ArrowLink';
import WaypointsList from '@/components/mapListView/directions/WaypointsList';
import ImageCarousel from '@/components/mapListView/directions/ImageCarousel';
import { shortenHtmlString } from '@/helpers/functions';
import OvalIcon from '@/components/common/icons/Oval';
import StartFlag from '@/components/common/icons/StartFlag';
import FinishFlag from '@/components/common/icons/FinishFlag';
import ReturnTrip from '@/components/common/icons/ReturnTrip';
import OneWay from '@/components/common/icons/OneWay';
import Reverse from '@/components/common/icons/Reverse';
import Spinner from '@/components/common/loading/Spinner';
import AddMarkerIcon from '@/components/common/icons/AddMarker';
import ClockFill from '@/components/common/icons/ClockFill';
import Speed from '@/components/common/icons/Speed';
import PinStack from '@/components/common/icons/PinStack';
import Toggle from '@/components/common/Toggle';
import AvoidToggle from '@/components/mapListView/AvoidToggle';
import UploadGPXButton from '@/components/mapListView/UploadGPXButton';

import SimpleHtmlRenderer from '@/components/common/SimpleHtmlRenderer';
import withRouter from '@/helpers/hooks';
import { connect } from 'react-redux';
import { getInputState } from '@/store/autocomplete';
import Downshift from '@/components/common/search/Downshift';
import DescriptionSidebar from '@/components/mapListView/sidebar/DescriptionSidebar';
import { get as getLocalStore } from '@/helpers/persistor';
import RideEvent from '@/components/common/RideEvent';
import { analyticsWithData } from '@/helpers/analytics';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    placeholderForCount,
    prettyTime,
    prettyMiles,
    prettyMPH,
    rideAverageSpeed,
    formatDateTime
} from '@/helpers/functions';
import {
    reverseWaypoints,
    isRideOneWay,
    toggleOneWay,
    updateRide,
    updateRouteShape,
    editRide
} from '@/store/edit_ride';
import { WaypointType, RideSubtype } from '@/helpers/constants';
import { rideTypeData } from '@/helpers/checkboxes';
import { setImageIndex, setImages } from '@/store/map';
import { nearestPointIndex } from '@/helpers/map';
import { isRideRecorded } from '@/store/rides';
import { dealerUrl } from '@/helpers/routes';
import { translate, formatTemp, formatSpeed } from '@/helpers/i18n';

const t = translate('directions.Dropdown');
const tRideType = translate('helpers.checkboxes');

const MAX_TOGGLE_WAYPOINTS_LABEL_LENGTH = 22;
const RIDE_FINISH_TIME_FORMAT = 'MMM D, YYYY [at] h:mm A';

//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Component -----------------------------------------------------------------
const DropdownDescription = ({
    ride,
    props,
    openSidebar,
    showFullDescription,
    setShowDescription,
    setShowImagesSidebar,
    onShowImages,
    setImageIndex,
    imageIndex,
    isCreate
}) => {
    const hasDealerInfo = !!(ride.dealerName || ride.subType === 'HOG_CHAPTER');
    const hasDescription = !!ride.description;

    return (
        <div>
            {(hasDealerInfo || hasDescription) && (
                <div className={style.description}>
                    {hasDealerInfo && (
                        <Link
                            className={style.dealer}
                            {...(ride.subType === 'EAGLE_RIDER'
                                ? {
                                      href: 'https://www.harley-davidson.com/us/en/rent-a-bike.html'
                                  }
                                : {
                                      to: `${dealerUrl(
                                          ride.dealerId
                                      )}?sidebar=1`
                                  })}>
                            {ride.dealerName
                                ? t('recommendedBy', {
                                      dealer:
                                          ride.recommendedBy || ride.dealerName,
                                      t: 'Recommended By: {dealer}'
                                  })
                                : t('Recommended Ride')}
                        </Link>
                    )}
                    {!!ride && ride.photos && ride.photos.length > 0 && (
                        <ImageCarousel
                            images={ride.photos}
                            setShowImagesSidebar={setShowImagesSidebar}
                            setImageIndex={setImageIndex}
                            imageIndex={imageIndex}
                            slidesPerView={1.4}
                            isHorizontal={true}
                            openSidebar={() => openSidebar('images')}
                            onShowImages={() => onShowImages(true)}
                        />
                    )}
                    {hasDescription &&
                        (showFullDescription ? (
                            <div style={{ paddingTop: '12px' }}>
                                <DescriptionSidebar
                                    description={ride.description}
                                    className={style.description}
                                    isOpen={showFullDescription}
                                    onClick={setShowDescription}
                                    onRequestClose={setShowDescription}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    paddingTop: '12px',
                                    fontSize: '14px'
                                }}>
                                <SimpleHtmlRenderer
                                    className={style.description}
                                    html={shortenHtmlString(
                                        ride.description,
                                        300
                                    )}
                                />{' '}
                                {ride.description.length > 300 && (
                                    <ArrowLink onClick={setShowDescription} />
                                )}
                            </div>
                        ))}
                </div>
            )}
            {!!ride && !!ride.event && !isCreate && (
                <RideEvent event={ride.event} dropdown={true} />
            )}
        </div>
    );
};

const Dropdown = (props) => {
    const {
        isOpen,
        toggleOneWay,
        reverseWaypoints,
        ride,
        isCreate,
        updateRide,
        canEdit,
        oneWay,
        openSidebar,
        setShowImagesSidebar,
        onShowImages,
        imageIndex
    } = props;
    const [value, setValue] = useState('');
    const [override, setOverride] = useState(false);
    const [isRideEvent, setIsRideEvent] = useState(false);
    const [showWaypoints, setShowWaypointsState] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [showAvoidPanel, setShowAvoidPanelState] = useState(false);
    const [showDirections, setShowDirections] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [weatherImported, setWeatherImported] = useState(false);
    const [startWeather, setStartWeather] = useState(null);
    const [endWeather, setEndWeather] = useState(null);

    const state = {
        value,
        override,
        isRideEvent,
        showWaypoints,
        urlError,
        showAvoidPanel,
        showDirections,
        showFullDescription,
        weatherImported,
        startWeather,
        endWeather
    };

    const setShowWaypoints = () => {
        const locationsAnalyticsState = !state.showWaypoints ? 'on' : 'off';
        const analyticsValue = 'list waypoints';
        analyticsWithData(analyticsValue, { value: locationsAnalyticsState });
        return setShowWaypointsState(!state.showWaypoints);
    };

    const setShowAvoidPanel = () =>
        setShowAvoidPanelState(!state.showAvoidPanel);
    const setShowDescription = () =>
        setShowFullDescriptionState(!state.showFullDescription);

    const fetchWeather = ({ weatherData }) => {
        setWeatherImported(true);
        if (!weatherData) return;
        const [startWeather, endWeather] = weatherData;
        setStartWeather({ startWeather, endWeather });
    };

    useEffect(() => {
        if (isOpen) setOverride(true);
    }, [isOpen]);

    useEffect(() => {
        if (
            isRideRecorded(ride) &&
            ride?.session?.createdTime &&
            !weatherImported
        ) {
            fetchWeather(ride);
        }
    }, [ride, weatherImported]);

    const renderTabs = () => {
        const {
            ride,
            currentRoutePoint,
            editable,
            editRide,
            meta,
            previewGPXRide,
            updateUploadModal,
            isCreate
        } = props;

        const { showWaypoints } = state;
        const { points, legs, waypoints, offRoad } = ride;
        const hasWaypoints = !!waypoints
            ? waypoints.findIndex((waypoint) => waypoint.type === 'WAYPOINT')
            : 1;
        const showTimeAndDistance =
            !showWaypoints || (showWaypoints && hasWaypoints == -1)
                ? true
                : false;
        const isOffRoadRoute = offRoad;
        const isRecordedRide = ride.subType === RideSubtype.RECORDED;

        // // Locations are all waypoints without type WAYPOINT
        const locations = (waypoints || []).filter(
            (waypoint) => waypoint.type !== WaypointType.WAYPOINT
        );

        const loadingWaypoints = !ride.waypoints;
        const isPreview = () => window.location.pathname.includes('/preview');

        const waypointIndex = nearestPointIndex(
            points,
            state.showWaypoints ? waypoints : locations,
            currentRoutePoint
        );

        return (
            <div
                className={classNames(style.inner, {
                    [style.recorded]: isRecordedRide,
                    [style.offroad]: isOffRoadRoute,
                    [style.preview]: isPreview
                })}
                style={{ flex: 2 }}>
                <div className={style.scroll}>
                    {loadingWaypoints ? (
                        <div className={style.loading}>
                            <Spinner />
                        </div>
                    ) : (
                        <WaypointsList
                            highlightIndex={waypointIndex}
                            editable={!isOffRoadRoute && editable}
                            waypoints={
                                state.showWaypoints ? waypoints : locations
                            }
                            legs={legs}
                            showTimeAndDistance={showTimeAndDistance}
                        />
                    )}
                </div>
                {isCreate &&
                    isOffRoadRoute &&
                    waypoints &&
                    !waypoints.length && (
                        <div>
                            <span className={style.gpxImportText}>
                                {t(
                                    'Click on the map to start creating your ride.'
                                )}
                            </span>
                        </div>
                    )}
                <div>
                    {isCreate && !isOffRoadRoute && (
                        <div className={style.upDownshift}>
                            {editable && PresentDownShift(locations)}
                        </div>
                    )}
                    {waypoints && !waypoints.length && (
                        <div>
                            <div className={style.createAlt}>{t('Or')}</div>
                            <div className={style.gpxImport}>
                                <span className={style.gpxImportText}>
                                    {t('Create ride from file')}
                                </span>
                                <span className={style.gpxImportButton}>
                                    <UploadGPXButton
                                        ride={ride}
                                        meta={meta}
                                        updateUploadModal={updateUploadModal}
                                        disabled={
                                            waypoints.length > 0 ||
                                            (ride || {}).shortId
                                        }
                                        editRide={editRide}
                                        previewGPXRide={previewGPXRide}
                                        position={'s'}
                                    />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const weatherDetails = ({ iconNumber, temperature, windSpeed }) => {
        return (
            <div>
                <img
                    src={`/img/accuweather/${iconNumber}.png`}
                    className={style.weatherDetails}
                />
                <span className={style.weatherDetailsText}>
                    {Math.round(formatTemp(temperature))}°
                </span>
                <img
                    src="/img/accuweather/32.png"
                    className={style.weatherDetails}
                />
                <span className={style.weatherDetailsText}>
                    {formatSpeed(windSpeed)}
                </span>
            </div>
        );
    };

    const renderDetails = () => {
        const { ride, canEdit } = props;
        const { waypoints, offRoad, subType } = ride;

        const isRecordedRide = subType === RideSubtype.RECORDED;

        const session = ride.session || {};
        const showBikeDetails =
            !session.notOnABike && (session.isARental || session.bikeMake);
        const endTime = session.finishedTime;
        const { startWeather, endWeather } = state;

        return (
            <div
                className={classNames(style.inner, {
                    [style.recorded]: isRecordedRide,
                    [style.offroad]: offRoad
                })}>
                <div
                    className={classNames(style.scroll, {
                        [style.recorded]: isRecordedRide
                    })}>
                    <div>
                        {!!ride &&
                            !!ride.subType &&
                            rideTypeDetail(ride, canEdit)}
                        {endTime && (
                            <p className={style.rideFinished}>
                                {t('Ride Finished:')}&nbsp;
                                {formatDateTime(
                                    endTime,
                                    RIDE_FINISH_TIME_FORMAT
                                )}
                            </p>
                        )}
                        {!isRecordedRide && !offRoad && (
                            <div className={style.icons}>
                                <div className={style.icon}>
                                    <ClockFill />
                                    <span>{prettyTime(ride.duration)}</span>
                                </div>
                                <div className={style.icon}>
                                    <PinStack />
                                    <span>{prettyMiles(ride.length)}</span>
                                </div>
                                <div className={style.icon}>
                                    <Speed />
                                    <span>
                                        {prettyMPH(rideAverageSpeed(ride))}
                                    </span>
                                </div>
                            </div>
                        )}
                        {isRecordedRide &&
                            ride.description &&
                            detailText(ride.description)}

                        {isRecordedRide &&
                            waypoints &&
                            waypoints.length > 0 && (
                                <div className={style.waypoints}>
                                    <div className={style.waypoint}>
                                        {arrowDown}
                                        <div>
                                            <StartFlag
                                                className={style.startFlag}
                                            />
                                            <div>
                                                {waypoints[0].address ||
                                                    `${waypoints[0].latitude}, ${waypoints[0].longitude}`}
                                            </div>
                                            {startWeather &&
                                                weatherDetails(startWeather)}
                                        </div>
                                    </div>
                                    <div className={style.waypoint}>
                                        <FinishFlag
                                            className={style.startFlag}
                                        />
                                        <div>
                                            {waypoints[waypoints.length - 1]
                                                .address ||
                                                `${
                                                    waypoints[
                                                        waypoints.length - 1
                                                    ].latitude
                                                }, ${
                                                    waypoints[
                                                        waypoints.length - 1
                                                    ].longitude
                                                }`}
                                        </div>
                                        {endWeather &&
                                            weatherDetails(endWeather)}
                                    </div>
                                </div>
                            )}
                        {showBikeDetails && detailHeader('Bike Used')}
                        {showBikeDetails && detailText(bikeDetail(ride))}
                    </div>
                </div>
            </div>
        );
    };

    const rideTypeDetail = (ride, canEdit, shortRideType = false) => {
        const { type } = ride;
        const isCuratedRide = type == 'CURATED';
        const rideType =
            !!isCuratedRide || !canEdit
                ? tRideType('Recommended')
                : tRideType('My Ride');
        let rideSubType = rideTypeData().find((t) => t.value === ride.subType);
        if (rideSubType == undefined) {
            rideSubType = rideTypeData().find((t) => t.value === 'HD');
        }
        const { offRoad } = ride;

        return offRoad ? (
            <div
                className={classNames(style.rideTypeDetail, {
                    [style.shortRideType]: !!shortRideType
                })}>
                <img src="/img/icon-helmet.svg" />
                {rideType} • {rideSubType.label} •
                <img src="/img/icon-off-road.svg" />
            </div>
        ) : (
            <div
                className={classNames(style.rideTypeDetail, {
                    [style.shortRideType]: !!shortRideType
                })}>
                <img src="/img/icon-helmet.svg" />
                {rideType} • {rideSubType.label}
            </div>
        );
    };

    const detailHeader = (text) => (
        <div className={style.rideDetails}>
            {/* <PencilIcon className={style.editIcon} /> */}
            <span className={style.detailHeader}>{text}</span>
        </div>
    );

    const detailText = (text) => <div className={style.detailText}>{text}</div>;

    const bikeDetailDisplay = (text, upcase = true) => {
        const styledText = upcase ? text.toUpperCase() : text;
        return <div dangerouslySetInnerHTML={{ __html: styledText }} />;
    };

    const getHDModelForBike = (hdModelNames, bike) =>
        !!hdModelNames && !!hdModelNames[bike.bikeYear]
            ? (
                  hdModelNames[bike.bikeYear].find(
                      (item) => item.mc === bike.bikeModel
                  ) || {}
              ).n
            : false;

    const getHDModelNameForBike = (bike) => {
        const hdModelNames = (getLocalStore('hdModelNames') || {}).data;
        return getHDModelForBike(hdModelNames, bike);
    };

    const bikeDetail = (ride) => {
        const bike = ride.session;
        if (!!bike.bikeName) return bikeDetailDisplay(bike.bikeName);
        if (bike.isARental)
            return bikeDetailDisplay('I was on a rental bike', false);
        if (bike.notOnABike)
            return bikeDetailDisplay('I was not on a bike', false);
        const hdModelNameForBike = getHDModelNameForBike(bike);
        return !!hdModelNameForBike
            ? bikeDetailDisplay(
                  `${bike.bikeYear} Harley-Davi dson ${hdModelNameForBike}`
              )
            : bikeDetailDisplay(
                  `${bike.bikeYear} ${bike.bikeMake} ${bike.bikeModel}`
              );
    };

    const arrowDown = (
        <svg
            className={style.arrowDown}
            width="8"
            height="42"
            viewBox="0 0 8 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 38.01L5 0L3 0L3 38.01H0L4 42L8 38.01H5Z"
                fill="#7F7F7F"
            />
        </svg>
    );

    const Locations = (showWaypoints, setShowWaypoints) => (
        <div className={style.locations}>
            <div className={style.locationsIcon}>
                <span>
                    <OvalIcon />
                    <span
                        className={style.locationText}
                        id="locations"
                        data-testid="locations">
                        {t('Locations')}
                    </span>
                </span>
                <span className={style.locationToggle}>
                    <Toggle
                        text={t('List Waypoints')}
                        className={classNames(
                            style.toggleWaypoints,
                            t('List Waypoints').length >
                                MAX_TOGGLE_WAYPOINTS_LABEL_LENGTH &&
                                style.smallFont
                        )}
                        value={showWaypoints}
                        onClick={setShowWaypoints}
                        id="waypoints-toggle"
                    />
                </span>
            </div>
        </div>
    );

    const PresentDownShift = (locations) => {
        return (
            <Downshift
                className={style.search}
                value={state.value}
                onSubmit={() => setValue('')}
                icon={
                    [StartFlag, FinishFlag][locations.length] || AddMarkerIcon
                }
                placeholder={placeholderForCount(locations.length)}
                hasLocations={locations.length > 0}
            />
        );
    };

    const { offRoad } = ride;
    const isPreview = () => window.location.pathname.includes('/preview');

    const cn = classNames(style.Dropdown, appStyle.rideDirectionsDropdown, {
        [style['expanded']]: isOpen,
        [style['override-transform']]: override,
        [style.directionsActive]: showDirections
        // [style.isDealer]: addDealerFunc
    });
    const onToggleRideEvent = () => {
        if (!!isRideEvent) updateRide('event', null);
        updateRide('hasEvent', !isRideEvent);
        setIsRideEvent(!isRideEvent);
    };
    const onToggleRideEventUrlError = (val) => {
        setUrlError({ urlError: val });
    };

    const isCreatePreview =
        window.location.pathname.includes('/create/preview');

    const canShowAvoid = !offRoad && (canEdit || isCreate || isCreatePreview);

    return (
        <div className={cn}>
            <div
                className={classNames(style.rideDetail, {
                    [style.recorded]: isRideRecorded(ride),
                    [style.preview]: isPreview()
                })}>
                {!isCreate &&
                    !isRideRecorded(ride) &&
                    !!ride &&
                    !!ride.subType &&
                    rideTypeDetail(ride, canEdit, true)}
            </div>
            {!isRideRecorded(ride) && (
                <DropdownDescription
                    ride={ride}
                    props={props}
                    isRideEvent={isRideEvent}
                    onToggleRideEvent={onToggleRideEvent}
                    isCreate={isCreate}
                    onToggleRideEventUrlError={onToggleRideEventUrlError}
                    urlError={urlError}
                    openSidebar={openSidebar}
                    showFullDescription={showFullDescription}
                    onShowImages={onShowImages}
                    setShowDescription={setShowDescription}
                    setShowImagesSidebar={setShowImagesSidebar}
                    setImageIndex={setImageIndex}
                    imageIndex={imageIndex}
                    setImages={setImages}
                />
            )}
            {!isRideRecorded(ride) &&
                Locations(showWaypoints, setShowWaypoints)}
            {isRideRecorded(ride) ? renderDetails() : renderTabs()}

            {!isRideRecorded(ride) && (
                <div className={style.footerActions}>
                    {canShowAvoid && (
                        <div className={style.avoidToolTip}>
                            <AvoidToggle
                                tooltipClassName={style.avoidToolTip}
                                showOptions={showAvoidPanel}
                                setShowOptions={setShowAvoidPanel}
                                isCreate={isCreate || isCreatePreview}
                            />
                        </div>
                    )}
                    {isCreate && (
                        <div className={style.footerContainer}>
                            <div className={style.footer}>
                                <Link
                                    id={oneWay ? 'round' : 'one-way'}
                                    data-testid={oneWay ? 'round' : 'one-way'}
                                    className={style.print}
                                    onClick={toggleOneWay}>
                                    {oneWay ? <ReturnTrip /> : <OneWay />}
                                    {oneWay ? t('Round Trip') : t('One Way')}
                                </Link>
                                <Link
                                    id="reverse"
                                    data-testid="reverse"
                                    className={style.print}
                                    onClick={reverseWaypoints}>
                                    <Reverse />
                                    {t('Reverse')}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {isRideRecorded(ride) && (
                <div className={style.footerActions}>
                    {canShowAvoid && (
                        <div className={style.avoidToolTip}>
                            <AvoidToggle
                                tooltipClassName={style.avoidToolTip}
                                showOptions={showAvoidPanel}
                                setShowOptions={setShowAvoidPanel}
                                isCreate={!isCreate || isCreatePreview}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const { currentRoutePoint, currentRouteProgress } = state.rides.preview;
    return {
        meta: state.edit_ride.present.meta,
        imageIndex: state.map.imageIndex,
        autocomplete: getInputState(state),
        currentRoutePoint,
        currentRouteProgress,
        oneWay: isRideOneWay(state),
        user: state.user
    };
};
// //------------------------------------------------------------------------------
// // Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeTooltip: () => dispatch(completeCreateStep('search')),
        editRide: (...props) => dispatch(editRide(...props)),
        updateRide: (field, value) => {
            dispatch(updateRide(field, value));
        },
        onChangeTransportMode: (ride, mode) => {
            dispatch(updateRide('transportMode', mode));
            dispatch(updateRouteShape(null, { isBike: !!mode }));
        },
        onChangeMapMode: (mode) => {
            dispatch(updateRide('offRoad', mode));
            dispatch(updateRouteShape(null, { isOffroad: !!mode }));
        },
        previewGPXRide: (ride) => dispatch(previewRide(ride)),
        reverseWaypoints: () => dispatch(reverseWaypoints()),
        toggleOneWay: () => dispatch(toggleOneWay()),
        onToggleDropdown: (value) => dispatch(openDropdown(value)),
        setShowImagesSidebar: (index, images) => {
            dispatch(setImageIndex(index));
            dispatch(setImages(images));
            dispatch(setShowImagesSidebar(true));
        },
        openSidebar: (value, data = null) =>
            dispatch(updateSidebar(value, data)),
        onShowImages: () => dispatch(setShowImages(true))
    };
};

Dropdown.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Dropdown)
);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
