/******************************************************************************\
 * File: RideEvent.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import MiniArrowUp from '@/components/common/icons/MiniArrowUp';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/RideEvent.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { formattedDateRideEvent } from '@/helpers/functions';
import { translate } from '@/helpers/i18n';
const t = translate('mapListView.RideEvent');
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const RideEvent = ({ event, isSidePanel, smaller, dropdown, ...rest }) => {
    const cn = classNames(style.RideEvent, {
        [style['isSidePanel']]: !!isSidePanel,
        [style['smaller']]: !!smaller,
        [style['dropdown']]: dropdown
    });

    // LearnMoreLink is used by analytics - Do not change
    return (
        <div className={cn} {...rest}>
            {!!event && !!event.rideDate && (
                <div>
                    <img src="/img/icon-event-small-black.png" />
                    <span>
                        {formattedDateRideEvent(
                            event.rideDate,
                            event.startTime
                        )}
                    </span>
                </div>
            )}
            {!!event && !!event.link && (
                <div style={{ marginTop: '-8px' }}>
                    <Link
                        to={event.link}
                        target="_blank"
                        role="menuitem"
                        className={style.LearnMoreLink}>
                        {t('Learn More')}&nbsp;
                        <MiniArrowUp className={style.smallerIcon} />
                    </Link>
                </div>
            )}
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RideEvent;
