/******************************************************************************\
 * File: Tooltip.jsx
 *
 * Author: Gigster
 *
 * Description: Onboarding tooltips
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Close from '@/components/common/icons/Close';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/Tooltip.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Tooltip = ({
    accent = false,
    children,
    className,
    innerRef,
    direction = 'top',
    hideArrow,
    onRequestClose,
    thin,
    text,
    style: styleProp
}) => {
    const [open, setOpen] = useState(true);
    const [size, setSize] = useState(0);
    const tooltipRef = useRef(null);

    useEffect(() => {
        const $el = tooltipRef.current;
        const calculatedSize = ['top', 'bottom'].includes(direction)
            ? $el.offsetHeight
            : $el.offsetWidth;
        setSize(calculatedSize);
        setOpen(true);
    }, [direction]);

    const cn = classNames(style.Tooltip, {
        [className]: !!className,
        [style['open']]: open,
        [style['arrow']]: !hideArrow,
        [style['accent']]: accent,
        [style['thin']]: !!thin,
        [style[direction]]: !!direction
    });

    return (
        <div
            className={cn}
            ref={(el) => {
                tooltipRef.current = el;
                if (innerRef) innerRef(el);
            }}
            style={{ ...(styleProp || {}), [direction]: -size }}>
            {onRequestClose && (
                <div className={style.Close} onClick={onRequestClose}>
                    <Close />
                </div>
            )}
            {text} {children}
        </div>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Tooltip;
