import Fuse from 'fuse.js';

const highlightText = (text, matches, tag = 'b') => {
    if (!(matches || []).length) return text;

    let pair = matches.shift();
    const result = [];

    // Build the formatted string
    for (let i = 0; i < text.length; i++) {
        const char = text.charAt(i);
        if (pair && i == pair[0]) {
            result.push(`<${tag}>`);
        }
        result.push(char);
        if (pair && i == pair[1]) {
            result.push(`</${tag}>`);
            pair = matches.shift();
        }
    }

    return result.join('');
};

const fuseHtmlHighlight = (result, keys = ['title']) => {
    const { item, matches } = result;

    return keys.reduce((memo, key) => {
        const match = (matches || []).find((m) => m.key === key);
        memo[key] = match ? highlightText(item[key], match.indices) : item[key];
        return memo;
    }, {});
};

const DEG_TO_RAD = Math.PI / 180;

const toRad = (x) => x * DEG_TO_RAD;

function haversineDistance(startCoords, endCoords) {
    const R = 6371000;

    const start = { latitude: startCoords[0], longitude: startCoords[1] };
    const end = { latitude: endCoords[0], longitude: endCoords[1] };

    const dLat = toRad(end.latitude - start.latitude);
    const dLon = toRad(end.longitude - start.longitude);
    const lat1 = toRad(start.latitude);
    const lat2 = toRad(end.latitude);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
            Math.sin(dLon / 2) *
            Math.cos(lat1) *
            Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
}

function dealerToSearchItem(dealer) {
    const { address1, city, countryCode, stateCode, postalCode } = dealer;

    const title = dealer.dealerName;

    const vicinity = [
        address1 !== title && address1,
        city,
        stateCode,
        postalCode,
        countryCode
    ]
        .filter((e) => e)
        .join(', ');

    return {
        id: dealer.dealerId,
        dealerId: dealer.dealerId,
        url: dealer.url,
        position: [dealer.latitude, dealer.longitude],
        title,
        type: 'DEALER',
        dealerName: dealer.dealerName,
        vicinity
    };
}

function searchResult(result, at = []) {
    const highlight = fuseHtmlHighlight(result, ['title', 'vicinity']) || {};
    const { item } = result;

    if (at.length > 1) {
        item.distance = haversineDistance(at, item.position);
    }

    return Object.assign(item, {
        highlightedTitle: highlight.title,
        highlightedVicinity: highlight.vicinity
    });
}

function highlightResults(results, text, at = []) {
    const fuse = new Fuse(results, {
        includeMatches: true,
        shouldSort: false,
        threshold: 1.0,
        keys: ['title', 'vicinity']
    });
    return fuse.search(text).map((item) => searchResult(item, at));
}

export {
    highlightText,
    fuseHtmlHighlight,
    haversineDistance,
    dealerToSearchItem,
    searchResult,
    highlightResults
};
