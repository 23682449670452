/******************************************************************************\
 * File: WaypointsList.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/directions/WaypointsList.scss';
//------------------------------------------------------------------------------
// Components  -----------------------------------------------------------------
import Item from './Item';
import Distance from './Distance';
import Hamburger from '@/components/common/icons/Hamburger';
import CloseIcon from '@/components/common/icons/Close';
import Tooltip from '@/components/common/form/Tooltip';
import PencilIcon from '@/components/common/icons/Pencil';
import { getMarkerProps } from '@/helpers/markers';
import { analyticsForRideWithData } from '@/helpers/analytics';
import { WaypointType } from '@/helpers/constants';

//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import {
    removeRidePoint,
    swapRidePoints,
    editRidePointAddress
} from '@/store/edit_ride';
import { editWaypoint } from '@/store/edit_ride/waypoints';
import { update as updateMap } from '@/store/map';
import { setRouteProgressToPoint } from '@/store/rides/preview';
import { setCurrentRouteProgress } from '@/store/rides';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { waypointName } from '@/helpers/functions';

import { translate } from '@/helpers/i18n';
const t = translate('directions.WaypointsList');

const iconComponentForWaypoint = (
    waypoint,
    i,
    arr,
    shouldEnumerate = false
) => {
    const {
        icon: IconComponent,
        type,
        number
    } = getMarkerProps(waypoint, i, arr, shouldEnumerate);

    const props =
        type === 'NUMBER' || type === 'HOTEL'
            ? {
                  style: {
                      width: 16,
                      height: 16,
                      marginLeft: -4
                  }
              }
            : {};

    return (
        <div
            id={
                type === 'START'
                    ? 'start-icon'
                    : type === 'END'
                      ? 'end-icon'
                      : undefined
            }
            data-testid={
                type === 'START'
                    ? 'start-icon'
                    : type === 'END'
                      ? 'end-icon'
                      : undefined
            }>
            <IconComponent className={style.icon} {...props}>
                {number}
            </IconComponent>
        </div>
    );
};
//------------------------------------------------------------------------------
// Inner Component -----------------------------------------------------------------
const Waypoint = ({
    className,
    leg,
    showTimeAndDistance,
    offRoad,
    highlight,
    ...rest
}) => {
    const elementRef = useRef(null);

    useEffect(() => {
        if (highlight) {
            elementRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [highlight]);

    return (
        <div className={style.itemWrapper} ref={elementRef}>
            <Item
                className={classNames(style.Waypoint, {
                    [className]: !!className
                })}
                {...rest}
            />
            {leg && showTimeAndDistance && (
                <Distance
                    className={style.distance}
                    length={leg.length}
                    time={leg.travelTime}
                    innerProps={{ className: style.span }}
                    isOffRoadRoute={!!offRoad}
                />
            )}
        </div>
    );
};

const DraggableWaypoint = ({
    id,
    index,
    onNameSubmit,
    onRemove,
    waypointsLength,
    type,
    icon,
    leg,
    hover,
    showTimeAndDistance,
    ...rest
}) => {
    return (
        <Draggable draggableId={`${id}`} index={index}>
            {(provided, snapshot) => (
                <div className={style.itemWrapper}>
                    <Waypoint
                        editable
                        innerRef={provided.innerRef}
                        className={classNames({
                            [style.dragging]: snapshot.isDragging
                        })}
                        {...provided.draggableProps}
                        {...rest}
                        icon={
                            <div
                                className={style.dragHandle}
                                {...provided.dragHandleProps}>
                                {!snapshot.isDragging && (
                                    <Tooltip
                                        value={t('Drag to reorder')}
                                        placement="e"
                                        noDelay
                                        fixed={
                                            <Hamburger
                                                className={classNames(
                                                    style.handle,
                                                    style.burger
                                                )}
                                            />
                                        }
                                    />
                                )}
                                <div
                                    className={classNames({
                                        [style.clickable]:
                                            rest.label === undefined ||
                                            rest.label === null
                                    })}
                                    onClick={() => {
                                        if (
                                            rest.label === undefined ||
                                            rest.label === null
                                        ) {
                                            onNameSubmit('');
                                        }
                                    }}>
                                    {hover &&
                                    (rest.label === undefined ||
                                        rest.label === null) ? (
                                        <Tooltip
                                            value={t('Click to name location')}
                                            placement="e"
                                            noDelay>
                                            <PencilIcon
                                                className={style.icon}
                                            />
                                        </Tooltip>
                                    ) : (
                                        icon
                                    )}
                                </div>
                            </div>
                        }
                        nameProps={{
                            id,
                            editable: true,
                            blurOnEnter: true,
                            placeholder: t('Enter location address')
                        }}
                        labelProps={{
                            initialFocus: rest.label === '',
                            editable: true,
                            blurOnEnter: true,
                            placeholder: t('Name this location'),
                            onSubmit: (name) =>
                                name !== rest.label && typeof name === 'string'
                                    ? onNameSubmit(name)
                                    : () => {}
                        }}
                        right={
                            <Tooltip
                                value={
                                    index === 0
                                        ? t('Remove starting point')
                                        : t('Remove this destination')
                                }
                                placement="e"
                                noDelay
                                fixed={
                                    <div
                                        className={style.handle}
                                        onClick={() => {
                                            if (index === 0) {
                                                analyticsForRideWithData(
                                                    'remove starting point',
                                                    { location: 'sidebar' }
                                                );
                                            } else if (
                                                index ===
                                                waypointsLength - 1
                                            ) {
                                                analyticsForRideWithData(
                                                    'remove destination',
                                                    { location: 'sidebar' }
                                                );
                                            } else if (
                                                type === WaypointType.LOCATION
                                            ) {
                                                analyticsForRideWithData(
                                                    'remove location',
                                                    { location: 'sidebar' }
                                                );
                                            } else {
                                                analyticsForRideWithData(
                                                    'remove waypoint',
                                                    { location: 'sidebar' }
                                                );
                                            }
                                            onRemove();
                                        }}>
                                        <CloseIcon className={style.close} />
                                    </div>
                                }
                            />
                        }
                    />
                    {leg &&
                        showTimeAndDistance &&
                        waypointsLength > 1 &&
                        index + 1 !== waypointsLength && (
                            <Distance
                                className={style.distance}
                                length={leg.length}
                                time={leg.travelTime}
                                innerProps={{ className: style.span }}
                            />
                        )}
                    {provided.placeholder}
                </div>
            )}
        </Draggable>
    );
};
//------------------------------------------------------------------------------
// Main component -----------------------------------------------------------------
const WaypointsList = ({
    editable,
    waypoints,
    legs,
    hoverWaypointId,
    setHoverWaypointId,
    setRouteProgressToPoint,
    highlightIndex,
    edit_ride,
    onRemoveWaypoint,
    onEditWaypoint,
    showTimeAndDistance,
    onDragEnd
}) => {
    const {
        ride: { offRoad },
        meta: { dirty }
    } = edit_ride;
    const shouldEnumerate = !!offRoad && !!dirty;
    const shouldDisplayAddressForPOI = offRoad;
    const locationOrPOI = ['LOCATION', 'POI'];

    const calculateName = (waypoint) => {
        if (
            !waypoint.name ||
            !waypoint.address ||
            !waypoint.addressComponents
        ) {
            if (
                shouldDisplayAddressForPOI &&
                (locationOrPOI.includes(waypoint.type) || !!waypoint.dealerId)
            ) {
                return `${waypoint.lat}, ${waypoint.lng}`;
            }
        } else {
            const name = shouldDisplayAddressForPOI
                ? (shouldDisplayAddressForPOI &&
                      (locationOrPOI.includes(waypoint.type) ||
                          !!waypoint.dealerId) &&
                      (waypoint.addressComponents || {}).label) ||
                  (waypoint || {}).address ||
                  (waypoint || {}).name
                : (waypoint.addressComponents || {}).label ||
                  (waypoint || {}).address ||
                  (waypoint || {}).address.name;
            return name;
        }
    };

    if (!editable) {
        return (
            <div className={classNames(style.WaypointsList, style.padding)}>
                {waypoints.map((waypoint, i, arr) => (
                    <Waypoint
                        key={waypoint.id}
                        isFirst={i === 0}
                        isLast={arr.length - 1 === i}
                        highlight={highlightIndex === i}
                        onMouseEnter={() => setHoverWaypointId(waypoint.id)}
                        onMouseLeave={() => setHoverWaypointId(null)}
                        onClick={() => {
                            const isLast = waypoints.length === i + 1;
                            setRouteProgressToPoint(waypoint, isLast);
                        }}
                        showTimeAndDistance={showTimeAndDistance}
                        leg={legs && legs[i] ? legs[i] : null}
                        icon={iconComponentForWaypoint(
                            waypoint,
                            i,
                            waypoints,
                            shouldEnumerate
                        )}
                        label={waypointName(waypoint)}
                        name={calculateName(waypoint)}
                    />
                ))}
            </div>
        );
    }

    return (
        <DragDropContext
            onDragEnd={(result) => {
                if (!result.destination) {
                    return;
                }
                const sourceIndex = result.source.index;
                const destIndex = result.destination.index;
                onDragEnd(waypoints[sourceIndex], waypoints[destIndex]);
            }}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        className={classNames(style.WaypointsList, {
                            [style.editable]: true,
                            [style['dragging-over']]: snapshot.isDraggingOver
                        })}>
                        {waypoints.map((waypoint, i, arr) => {
                            const waypointLabel = (waypoint) => {
                                if (
                                    waypoint.address === undefined &&
                                    waypoint.name === undefined &&
                                    waypoint.label === undefined &&
                                    waypoint.addressComponents === undefined
                                ) {
                                    return `${waypoint.lat},${waypoint.lng}`;
                                }

                                const waypointAddress = waypoint.address
                                    ?.split(',')
                                    .slice(0, 1)
                                    .join();

                                if (
                                    waypoint.name !== 'My Current Location' &&
                                    (waypoint.name ||
                                        waypoint.title ||
                                        waypoint.value)
                                ) {
                                    return (
                                        waypoint.name ||
                                        waypoint.title ||
                                        waypoint.value
                                    );
                                }

                                if (
                                    waypointAddress &&
                                    waypoint.addressComponents?.title &&
                                    !waypoint.addressComponents.title.includes(
                                        waypointAddress
                                    )
                                ) {
                                    return waypoint.addressComponents.title;
                                }

                                return (
                                    waypoint.addressComponents?.title ||
                                    waypoint.name
                                );
                            };

                            return (
                                <DraggableWaypoint
                                    index={i}
                                    key={waypoint.id}
                                    id={waypoint.id}
                                    isFirst={i === 0}
                                    highlight={highlightIndex === i}
                                    isLast={arr.length - 1 === i}
                                    hover={waypoint.id === hoverWaypointId}
                                    onRemove={() =>
                                        onRemoveWaypoint(
                                            waypoint.id,
                                            i,
                                            offRoad
                                        )
                                    }
                                    onNameSubmit={(name) =>
                                        onEditWaypoint(
                                            waypoint.id,
                                            { name },
                                            offRoad
                                        )
                                    }
                                    onMouseEnter={() =>
                                        setHoverWaypointId(waypoint.id)
                                    }
                                    onMouseLeave={() =>
                                        setHoverWaypointId(null)
                                    }
                                    waypointsLength={waypoints.length}
                                    leg={legs && legs[i] ? legs[i] : null}
                                    showTimeAndDistance={showTimeAndDistance}
                                    icon={iconComponentForWaypoint(
                                        waypoint,
                                        i,
                                        waypoints,
                                        shouldEnumerate
                                    )}
                                    type={waypoint.type}
                                    name={
                                        waypoint.address ||
                                        waypoint.name ||
                                        `${waypoint.lat},${waypoint.lng}`
                                    }
                                    label={waypointLabel(waypoint)}
                                />
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => ({
    edit_ride: state.edit_ride.present,
    hoverWaypointId: state.map.hoverWaypointId
});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
    setHoverWaypointId: (id) => dispatch(updateMap('hoverWaypointId', id)),
    setRouteProgressToPoint: (point, isLast) =>
        dispatch(setRouteProgressToPoint(point, isLast)),
    onDragEnd: (a, b) => dispatch(swapRidePoints(a, b)),
    onEditWaypoint: (id, edits, offRoad) =>
        dispatch(editWaypoint(id, edits, offRoad)),
    onEditWaypointAddress: (id, address) =>
        dispatch(editRidePointAddress(id, address)),
    onRemoveWaypoint: (id, i, offRoad) => {
        dispatch(removeRidePoint(id, i, offRoad));
        dispatch(setCurrentRouteProgress(0));
    }
});
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(WaypointsList);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
