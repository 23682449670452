import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import throttle from 'lodash/throttle';
import style from '@/style/mapListView/Map.scss';
import Marker from '@/components/common/map/Marker';
import Line from '@/components/common/map/Line';
import Waypoint from '@/components/common/map/icons/Waypoint';
import MyLocation from '@/components/common/map/icons/MyLocation';
import Tooltip from '@/components/common/Tooltip';
import ContextMenu, { MenuItem } from '@/components/mapListView/ContextMenu';
import RenderRide from '@/components/mapListView/RenderRide';
import { setCurrentRouteProgress, resetCurrentRoutePoint } from '@/store/rides';
import {
    addRidePoint,
    alternativeRoutes,
    changeRoute,
    editRidePoint,
    previewRidePoint,
    insertRidePoint,
    insertRidePointNearest,
    removeRidePointNearest,
    removeRidePoint,
    routing_editRide,
    clearRide,
    resetRidePoints
} from '@/store/edit_ride';
import { completeCreateStep } from '@/store/tutorial';
import MobileDownloadCTA from '@/components/common/MobileDownloadCTA';
import { placeholderForCount } from '@/helpers/functions';
import { fastFindNearest, normalizeEvent } from '@/helpers/map';
import { WaypointType } from '@/helpers/constants';
import { fetchPOI } from '@/store/poi';
import mobile from '@/helpers/is-mobile';
import { analyticsForRideWithData } from '@/helpers/analytics';
import { translate } from '@/helpers/i18n';
import withRouter from '@/helpers/hooks';
import MapContext from '@/contexts/MapContext';
const t = translate('views.MapCreate');

const TinyTooltip = ({ style, text }) => (
    <div
        style={{
            ...style,
            position: 'absolute',
            width: '240px',
            pointerEvents: 'none'
        }}>
        <Tooltip direction="bottom" thin hideArrow>
            {text}
        </Tooltip>
    </div>
);

const MapCreateContextMenu = ({
    addRideEndPoint,
    addRideStartPoint,
    contextMenu: { style, pointId, geocoord, meta, i },
    insertRidePointNearest,
    insertWaypointNearest,
    convertRidePoint,
    offRoad,
    numWaypoints,
    waypointIds,
    onRequestClose,
    removeRidePoint
}) => {
    return (
        <ContextMenu style={style} onRequestClose={onRequestClose}>
            {pointId !== undefined ? (
                <div>
                    {meta.canConvert && (
                        <MenuItem
                            onClick={() =>
                                convertRidePoint(pointId, meta.type)
                            }>
                            {meta.type === WaypointType.WAYPOINT
                                ? t('Change to location')
                                : t('Change to waypoint')}
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            removeRidePoint(waypointIds[i], null, offRoad);
                            if (waypointIds.indexOf(pointId) === 0) {
                                analyticsForRideWithData(
                                    'remove starting point',
                                    {
                                        location: 'map'
                                    }
                                );
                            } else if (
                                waypointIds.indexOf(pointId) ===
                                waypointIds.length - 1
                            ) {
                                analyticsForRideWithData('remove destination', {
                                    location: 'map'
                                });
                            } else if (meta.type === WaypointType.LOCATION) {
                                analyticsForRideWithData('remove location', {
                                    location: 'map'
                                });
                            } else {
                                analyticsForRideWithData('remove waypoint', {
                                    location: 'map'
                                });
                            }
                        }}>
                        {meta.type === WaypointType.WAYPOINT
                            ? t('Remove waypoint')
                            : t('Remove location')}
                    </MenuItem>
                </div>
            ) : (
                <div>
                    {numWaypoints >= 2 && (
                        <MenuItem
                            onClick={() => {
                                analyticsForRideWithData('add waypoint', {
                                    lat: geocoord.lat,
                                    lng: geocoord.lng,
                                    location: 'map'
                                });
                                return insertWaypointNearest(geocoord);
                            }}>
                            {t('Add waypoint')}
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            const str = !numWaypoints
                                ? 'add starting point'
                                : numWaypoints < 2
                                  ? 'add destination'
                                  : 'add location';

                            analyticsForRideWithData(str, {
                                lat: geocoord.lat,
                                lng: geocoord.lng,
                                location: 'map'
                            });
                            return insertRidePointNearest(geocoord);
                        }}>
                        {placeholderForCount(numWaypoints)}
                    </MenuItem>
                    {numWaypoints >= 2 && !offRoad && (
                        <div>
                            <MenuItem
                                onClick={() => {
                                    analyticsForRideWithData(
                                        'set as starting point',
                                        {
                                            lat: geocoord.lat,
                                            lng: geocoord.lng,
                                            location: 'map'
                                        }
                                    );
                                    return addRideStartPoint(geocoord);
                                }}>
                                {t('Set as starting point')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    analyticsForRideWithData(
                                        'set as destination',
                                        {
                                            lat: geocoord.lat,
                                            lng: geocoord.lng,
                                            location: 'map'
                                        }
                                    );
                                    return addRideEndPoint(geocoord);
                                }}>
                                {t('Set as destination')}
                            </MenuItem>
                        </div>
                    )}
                </div>
            )}
        </ContextMenu>
    );
};

class MapCreate extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dot: null,
            isTouchDevice: mobile.isTouchDevice(),
            waypointsIds: []
        };
    }

    componentDidMount() {
        const {
            edit_ride,
            loadRide,
            clearRide,
            params,
            onResetCurrentRoutePoint
        } = this.props;
        const { id } = params;

        const hasNoWaypoints =
            !edit_ride.waypoints || (edit_ride.waypoints || []).length < 1;
        // only reload if we have an id, and it's not the same id as before
        // e.g. from navigating back from ride preivew
        const isEditingTheSamePreviousRide = edit_ride.shortId == id;
        if (!isEditingTheSamePreviousRide || hasNoWaypoints) {
            clearRide();
            onResetCurrentRoutePoint();
            if (id) loadRide(id);
        }
    }

    componentDidUpdate(prevProps) {
        const { waypoints: prevWaypoints } = prevProps.edit_ride;
        const { waypoints } = this.props.edit_ride;
        const { onResetCurrentRoutePoint } = this.props;
        const prevWaypointIds = prevWaypoints?.map((w) => w.id) || [];
        const waypointIds = waypoints?.map((w) => w.id) || [];
        if (prevWaypointIds.length !== waypointIds.length) {
            this.setState({ waypointsIds: waypointIds });

            if (prevWaypoints.length <= 0 || waypoints.length <= 0)
                onResetCurrentRoutePoint();
        }
    }

    updateDot = (e) => {
        const { map } = this.context;
        const event = normalizeEvent(e, map);
        const { geocoord } = event;

        const { points } = this.props.edit_ride;
        if (geocoord) {
            const closestPoint = fastFindNearest(geocoord, points);
            if (closestPoint) {
                this.setState({ dot: closestPoint });
                this.showTooltip(event);
                this.setCursor('pointer');
            }
        }
    };

    showTooltip = ({ pageY: top, pageX: left }, canRemove) => {
        if (top && left)
            this.setState({
                tooltip: { top: `${top}px`, left: `${left}px`, canRemove }
            });
    };

    onDotEnter = (e) => {
        const { map } = this.context;
        const event = normalizeEvent(e, map);
        this.showTooltip(event, true);
        this.setCursor('pointer');
    };

    hideDot = () => {
        this.setState({ dot: null, tooltip: null });
        this.setCursor();
    };

    beginDotDrag = () => {
        const { dot } = this.state;
        if (!dot) return;

        this.setState({ start: dot, tooltip: null });

        // bind drag handlers
        const { map } = this.context;
        map.addEventListener('dragstart', this.dragStartHandler);
        map.addEventListener('drag', this.dragHandler);
        map.addEventListener('dragend', this.dragEndHandler);
    };

    // setup drag params
    isDragging = false;
    // tracks pageX, pageY
    currPosition = {};
    // tracks pageX, pageY
    lastThrottledPosition = { pageX: 0, pageY: 0 };
    // tracks geo coords
    existingWaypoints = [];

    thresholdExceeded = (curr, threshold = 5) => {
        const { pageX, pageY } = this.lastThrottledPosition;
        const x = Math.abs(pageX - curr.pageX);
        const y = Math.abs(pageY - curr.pageY);
        return x > threshold || y > threshold;
    };

    throttleUpdate = throttle(
        (dot, start) => {
            const { pageX, pageY } = this.currPosition;
            const { offRoad } = this.props.edit_ride;
            const exceeded = this.thresholdExceeded(this.currPosition);
            if (exceeded) {
                this.lastThrottledPosition = { pageX, pageY };
                if (
                    offRoad === false ||
                    offRoad === null ||
                    offRoad === undefined ||
                    (offRoad === true && this.isDragging === true)
                ) {
                    this.props.resetRidePoints(this.existingWaypoints);
                }
                this.insertDot(dot, start);
            }
        },
        1000,
        { trailing: true }
    );

    dragStartHandler = (e) => {
        const { pageX, pageY } = e.originalEvent;
        this.currPosition = { pageX, pageY };
        this.lastThrottledPosition = { pageX, pageY };
        const { waypoints } = this.props.edit_ride || {};
        // track existing positions
        this.existingWaypoints = waypoints;
        this.isDragging = true;
        const { behavior } = this.context;
        // prevent dragging map
        behavior.disable();
    };

    dragHandler = (e) => {
        const { map } = this.context;
        const event = normalizeEvent(e, map);
        const { pageX, pageY } = e.originalEvent;
        this.currPosition = { pageX, pageY };
        if (!!event && !!event.geocoord) {
            event.geocoord.isOnRoute = true;
            this.setState({ dot: event.geocoord });
            this.throttleUpdate(event.geocoord, this.state.start);
        }
    };

    dragEndHandler = () => {
        const { map, behavior } = this.context;
        this.isDragging = false;
        this.setState({ start: null, dot: null });

        // cleanup
        behavior.enable();
        map.removeEventListener('dragstart', this.dragStartHandler);
        map.removeEventListener('drag', this.dragHandler);
        map.removeEventListener('dragend', this.dragEndHandler);
        this.hideDot();
    };

    routeClick = (e) => {
        const { map } = this.context;
        const event = normalizeEvent(e, map);
        if (!this.isDragging && !!event && !!event.geocoord) {
            this.setState({ dot: event.geocoord });
            event.geocoord.isOnRoute = true;
            this.insertDot(event.geocoord);

            //remove map listeners
            map.removeEventListener('dragstart', this.dragStartHandler);
            map.removeEventListener('drag', this.dragHandler);
            map.removeEventListener('dragend', this.dragEndHandler);
        }
    };

    includesPoint = (point, points) => {
        if (!!points && points.length > 0) {
            points.filter(
                (pt) =>
                    point.lat === pt.lat &&
                    point.lng === pt.lng &&
                    point.type === WaypointType.WAYPOINT
            );
        }
    };

    insertDot = (waypoint, near) => {
        if (!waypoint) return;
        const dot = { ...waypoint, type: WaypointType.WAYPOINT };
        const includesPoint =
            this.includesPoint(dot, this.props.edit_ride.points) || [];

        if (includesPoint.length === 0)
            this.props.insertRidePointNearest(dot, near);
        this.hideDot();
    };

    setCursor = (type) => (document.body.style['cursor'] = type || '');

    render() {
        const {
            className,
            alternatives,
            poi,
            map,
            edit_ride,
            meta,
            contextMenu,
            changeRoute,
            closeContextMenu,
            tooltip,
            hideTooltip,
            selectedMarkerId,
            currentRoutePoint,
            hoverWaypointId,
            navigate
        } = this.props;

        const { myLocation } = map;

        const ride = edit_ride || {};
        const { points, sections, waypoints, offRoad } = ride;

        const { dot, isTouchDevice, tooltip: tinyTooltip } = this.state;

        const { isMobile } = this.props;
        const goBack = () => navigate(-1);
        const cn = classNames(style.Content, {
            [className]: className
        });
        if (isMobile) {
            return <MobileDownloadCTA isOpen={true} onRequestClose={goBack} />;
        }

        return (
            <div className={cn}>
                <Helmet>
                    <title>{ride.id ? ride.name : t('Ride Create')}</title>
                </Helmet>

                {/* Current scrubber dot on route: */}
                {!!currentRoutePoint && currentRoutePoint !== 0 && (
                    <Marker
                        position={currentRoutePoint}
                        component={MyLocation}
                        componentProps={{
                            color: '#000'
                        }}
                        zIndex={100}
                    />
                )}

                {myLocation && (
                    <Marker position={myLocation} component={MyLocation} />
                )}
                {/* Alternate Paths: */}
                {!!alternatives.length && points?.length > 1 && (
                    <div>
                        {alternatives.map((alternative, i) => (
                            <Line
                                zIndex={3}
                                sections={sections}
                                key={alternative.id}
                                onClick={() => changeRoute(alternative.id)}
                                onPointerEnter={() => this.setCursor('pointer')}
                                onPointerLeave={() => this.setCursor()}
                                points={alternative.points}
                                bounds={bounds}
                                style={{
                                    lineWidth: 6,
                                    strokeColor: '#929292'
                                }}
                            />
                        ))}
                    </div>
                )}

                {!!points?.length && (
                    <div>
                        {/* Thicker, transparent line for events (better ux): */}
                        <Line
                            zIndex={5}
                            sections={sections}
                            points={points}
                            onPointerMove={this.updateDot}
                            onPointerLeave={this.hideDot}
                            onPointerDown={this.beginDotDrag}
                            onClick={this.routeClick}
                            style={{
                                lineWidth: 6,
                                strokeColor: 'rgba(255,102,0,1)'
                            }}
                        />
                    </div>
                )}

                {tinyTooltip && (
                    <TinyTooltip
                        style={tinyTooltip}
                        text={`${t('Drag to change route')}${
                            tinyTooltip.canRemove ? t(', click to remove') : ''
                        }`}
                    />
                )}

                {dot && (
                    <Marker
                        position={dot}
                        component={Waypoint}
                        draggable={true}
                        previewRidePoint={previewRidePoint}
                        componentProps={{
                            style: {
                                pointerEvents: 'none',
                                hoverIndicator: true
                            }
                        }}
                    />
                )}
                {!!edit_ride && (
                    <RenderRide
                        ride={ride}
                        enumerateWaypointIcons={meta.dirty && ride.offRoad}
                        isDirty={meta.dirty}
                        dashedLinesProps={{ dealers: poi.harley, map }}
                        selectedId={selectedMarkerId}
                        highlightId={hoverWaypointId}
                        waypointProps={(point, i, points) => ({
                            draggable:
                                i === 0 ||
                                i === points.length - 1 ||
                                WaypointType.POI !== point.type,
                            onClick: (event) => {
                                const rightClick =
                                    event.originalEvent.button === 2;
                                switch (point.type) {
                                    case WaypointType.WAYPOINT:
                                        if (!rightClick) {
                                            this.props.removeRidePoint(
                                                this.state.waypointsIds[i],
                                                i,
                                                ride.offRoad
                                            );
                                        }
                                        break;
                                    case WaypointType.POI:
                                    case WaypointType.LOCATION:
                                        if (!rightClick) {
                                            this.props.onWaypointClickId(
                                                point.id,
                                                i
                                            );
                                        }
                                        break;
                                }
                            },
                            onPointerMove: (event) =>
                                point.type === WaypointType.WAYPOINT &&
                                this.onDotEnter(event),
                            onPointerLeave: (event) =>
                                point.type === WaypointType.WAYPOINT &&
                                this.hideDot(),
                            onContextMenu: (event) => {
                                return this.props.openContextMenu(
                                    event,
                                    point.id,
                                    i,
                                    {
                                        canConvert:
                                            !!ride.offRoad ||
                                            (point.type !== WaypointType.POI &&
                                                i !== 0 &&
                                                i !== points.length - 1),
                                        type: point.type
                                    }
                                );
                            },
                            onDragEnd: (e) => {
                                const resetData =
                                    point.type === WaypointType.POI
                                        ? {
                                              id: uuid(),
                                              type: WaypointType.LOCATION,
                                              category: undefined,
                                              name: undefined,
                                              hereId: undefined,
                                              eventId: undefined,
                                              dealerId: undefined
                                          }
                                        : { isDragging: true };
                                const { waypoints } = this.props.edit_ride;
                                if (waypoints.length < 1) return;
                                this.props.editRidePoint(point.id, {
                                    ...e.geocoord,
                                    ...resetData
                                });
                            },
                            previewRidePoint: (e) => {
                                const resetData =
                                    point.type === WaypointType.POI
                                        ? {
                                              id: uuid(),
                                              type: WaypointType.LOCATION,
                                              category: undefined,
                                              name: undefined,
                                              hereId: undefined,
                                              eventId: undefined,
                                              dealerId: undefined
                                          }
                                        : {};
                                this.props.previewRidePoint(point.id, {
                                    ...e.geocoord,
                                    ...resetData,
                                    type: point.type
                                });
                            }
                        })}
                    />
                )}

                {contextMenu && (
                    <MapCreateContextMenu
                        onRequestClose={() => {
                            closeContextMenu();
                            hideTooltip();
                        }}
                        contextMenu={contextMenu}
                        insertRidePointNearest={(waypoint) =>
                            this.props.insertRidePointNearest({
                                ...waypoint,
                                type: WaypointType.LOCATION
                            })
                        }
                        addRideEndPoint={(waypoint) =>
                            this.props.addRideEndPoint(waypoint)
                        }
                        addRideStartPoint={(waypoint) =>
                            this.props.addRideStartPoint(waypoint)
                        }
                        insertWaypointNearest={(waypoint) =>
                            this.props.insertRidePointNearest({
                                ...waypoint,
                                type: WaypointType.WAYPOINT
                            })
                        }
                        convertRidePoint={(id, type) =>
                            this.props.editRidePoint(
                                id,
                                {
                                    type:
                                        type === WaypointType.WAYPOINT
                                            ? WaypointType.LOCATION
                                            : WaypointType.WAYPOINT
                                },
                                ride.offRoad
                            )
                        }
                        fetchPOI={this.props.fetchPOI}
                        offRoad={offRoad}
                        removeRidePoint={this.props.removeRidePoint}
                        numWaypoints={waypoints.length}
                        waypointIds={waypoints.map((w) => w.id)}
                    />
                )}

                {tooltip && isTouchDevice && (
                    <div className={style.tooltipPosition}>
                        <Tooltip
                            accent
                            hideArrow
                            style={{ pointerEvents: 'all' }}
                            text={t('Long press the map to add a marker')}
                            onRequestClose={hideTooltip}
                        />
                    </div>
                )}
            </div>
        );
    }
}
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    const edit_ride = state.edit_ride.present;
    const { create } = state.tutorial;
    return {
        alternatives: alternativeRoutes(state),
        poi: state.poi,
        edit_ride: edit_ride.ride,
        meta: edit_ride.meta,
        map: state.map,
        currentRoutePoint: state.rides.preview.currentRoutePoint,
        selectedMarkerId: state?.map?.selectedData?.id,
        hoverWaypointId: state.map.hoverWaypointId,
        tooltip: create?.map,
        currentRouteProgress: state?.rides?.preview?.currentRouteProgress
    };
};
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addRideEndPoint: (coord) => dispatch(addRidePoint(coord)),
        addRideStartPoint: (coord) => dispatch(insertRidePoint(0, coord)),
        editRidePoint: (id, edits) => dispatch(editRidePoint(id, edits)),
        previewRidePoint: (id, edits, offRoad) =>
            dispatch(previewRidePoint(id, edits, offRoad)),
        loadRide: (id) => dispatch(routing_editRide(id)),
        clearRide: () => dispatch(clearRide()),
        resetRidePoints: (waypoints) => dispatch(resetRidePoints(waypoints)),
        fetchPOI: (position) => dispatch(fetchPOI(position)),
        hideTooltip: () => dispatch(completeCreateStep('map')),
        removeRidePoint: (id, i) => dispatch(removeRidePoint(id, i)),
        removeRidePointNearest: (waypoint, nearest) =>
            dispatch(removeRidePointNearest(waypoint, nearest)),
        changeRoute: (id) => dispatch(changeRoute(id)),
        insertRidePointNearest: (waypoint, nearest) => {
            dispatch(insertRidePointNearest(waypoint, nearest));
            if (ownProps.currentRouteProgress > 0 || ownProps.edit_ride.id)
                dispatch(setCurrentRouteProgress(0));
        },
        onResetCurrentRoutePoint: () => {
            dispatch(resetCurrentRoutePoint());
            dispatch(setCurrentRouteProgress(0));
        }
    };
};
MapCreate.contextType = MapContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MapCreate)
);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
