/**
 * TODO: remove this when generatePath is included in a release of react-router
 * Source: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/generatePath.js
 */
const { compile } = require('path-to-regexp');

const patternCache = {};
const cacheLimit = 10000;
let cacheCount = 0;

const compileGenerator = (pattern) => {
    const cacheKey = pattern;
    const cache = patternCache[cacheKey] || (patternCache[cacheKey] = {});

    if (cache[pattern]) return cache[pattern];

    const compiledGenerator = compile(pattern);

    if (cacheCount < cacheLimit) {
        cache[pattern] = compiledGenerator;
        cacheCount++;
    }

    return compiledGenerator;
};

/**
 * Public API for generating a URL pathname from a pattern and parameters.
 */
const generatePath = (pattern = '/', params = {}) => {
    if(params.id) {
        params.id = String(params.id)
    }
    if (pattern === '/') {
        return pattern;
    }
    const generator = compileGenerator(pattern);
    return generator(params);
};

export default generatePath;
