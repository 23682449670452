import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import withRouter from '@/helpers/hooks';

import style from '@/style/mapListView/MapListView.scss';
import Map from '@/components/mapListView/Map';
import MapCommon from '@/components/mapListView/views/MapCommon';
import MapDefault from '@/components/mapListView/views/MapDefault';
import MapCreate from '@/components/mapListView/views/MapCreate';
import MapPreview from '@/components/mapListView/views/MapPreview';
import SidepanelContainer from '@/components/mapListView/sidepanel/SidepanelContainer';
import PreviewFooter from '@/components/mapListView/PreviewFooter';
import PrintView from '@/components/mapListView/directions/PrintView';
import ImageView from '@/components/mapListView/directions/ImageView';
import UiControls from '@/components/common/map/here/UiControls';
import HEREMapsLogo from '@/components/common/map/here/HEREMapsLogo';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import SidepanelFilter from '@/components/mapListView/sidepanel/SidepanelFilter';
import { fetchPOI } from '@/store/poi';
import {
    analyticsPOILocationDetailEvent,
    dataForAnalytics
} from '@/helpers/analytics';
import { ensureGigyaWebSDK } from '@/helpers/gigya';
import {
    removeOsanoWidgetIcon,
    undoOsanoWidgetRemoval
} from '@/helpers/removeOsanoWidgetIcon';
import { resetScrub } from '@/store/rides/preview';
import * as ridesActions from '@/store/rides';
import * as mapActions from '@/store/map';
import { transformRide } from '@/store/poi/helpers';
import { selectItemById as selectDealerId, selectItem } from '@/store/dealers';
import { selectItemById as selectEventId } from '@/store/events';
import { currentUserId } from '@/store/auth';
import {
    isChildOf,
    setupGeolocation,
    cleanupGeolocation,
    normalizeLatLng,
    ridePreviewViews,
    rideViews,
    onRideView,
    getQueryStringAsObject
} from '@/helpers/functions';
import { isLatLngInBounds } from '@/helpers/map';
import { Routes, isType, match } from '@/helpers/routes';
import { getFilteredRides, modifiedSelectedRides } from '@/helpers/rides';
import {
    getRideCheckboxData,
    countByKey,
    initialRidesFilters
} from '@/helpers/checkboxes';
import { get as getLocalStore } from '@/helpers/persistor';
import MCP from '@/helpers/MCP';
import { createLogger } from '@/helpers/debug';
//App context
import AppContext from '@/contexts/AppContext';

const log = createLogger('Map List View', true);
class MapListView extends React.Component {
    constructor(props) {
        super(props);
        this.intervalo = null;
    }
    state = {
        contextMenu: false,
        isInitialLoad: true,
        print: false,
        showSidepanelFilter: false,
        showSidePanel: true,
        previewOpen: true
    };

    isType = (types) => isType(this.props.location.pathname, types);
    routeHasId = (pathname) => {
        const hasId =
            matchPath({ path: Routes.MAP_RIDE }, pathname) ||
            matchPath({ path: Routes.MAP_DEALER }, pathname) ||
            matchPath({ path: Routes.MAP_EVENT }, pathname) ||
            matchPath({ path: Routes.SHARED_EVENT }, pathname) ||
            matchPath({ path: Routes.RIDE_EDIT }, pathname) ||
            matchPath({ path: Routes.RIDE_PREVIEW }, pathname) ||
            matchPath({ path: Routes.SHARED_RIDE }, pathname);

        return hasId ? true : false;
    };
    isPreview = (pathname) => {
        const isPreview = matchPath({ path: Routes.RIDE_PREVIEW }, pathname);
        return isPreview ? true : false;
    };
    componentDidMount = () => {
        const {
            onInitialLoad,
            location,
            updateRideFilters,
            fetchRideTags,
            rideTags
        } = this.props;

        this.intervalo = removeOsanoWidgetIcon(this);

        if (this.state.isInitialLoad && !rideTags) fetchRideTags();
        document.addEventListener('keydown', this.overridePrint, false);

        const filteredTypes = getLocalStore('filteredTypes');
        updateRideFilters(filteredTypes || initialRidesFilters);

        if (this.isType(['MAP', 'CREATE'])) {
            log('awaiting location and map...');
            const search = new URLSearchParams(location.search);
            const pos =
                search.get('pos') ||
                search.get('cacheId') ||
                search.get('sidebar');

            const dealerOrEvent = match(
                this.props.location.pathname,
                { exact: true },
                [Routes.MAP_DEALER, Routes.MAP_EVENT]
            );

            MCP.addJob(
                [mapActions.DID_RECEIVE_LOCATION, mapActions.MAP_HAS_LOADED],
                onInitialLoad(!pos && !dealerOrEvent),
                true
            );
        }
        this.state.isInitialLoad = false;
        // If browser has geolocation, setup callbacks
        ensureGigyaWebSDK(() => {
            this.watchGeoId = setupGeolocation(
                this.didReceiveLocation,
                window.hd.gigya
            );
        });

        this.props.onMapHasLoaded();
    };

    didReceiveLocation = (locationInfo) => {
        const { onReceiveLocation, centerMyLocation } = this.props;
        onReceiveLocation(locationInfo);
        centerMyLocation();
        cleanupGeolocation(this.watchGeoId);
    };

    setPreviewOpen = () =>
        this.setState({ previewOpen: !this.state.previewOpen });

    //ldgonzalezmedina fix this
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // show search this area if new q or zoom
        const {
            setShowSearchThisArea,
            location,
            rides,
            ridesFilters,
            userId,
            updateRidesFiltered
        } = this.props;

        //updates rides filtered prop
        if (
            rides !== nextProps.rides ||
            rides.length !== nextProps.rides.length
        ) {
            const filteredRides = getFilteredRides(
                ridesFilters,
                nextProps.rides,
                userId
            );
            updateRidesFiltered(filteredRides);
        }

        const isIdRoute = this.routeHasId(location.pathname);
        const willBeIdRoute = this.routeHasId(nextProps.location.pathname);
        if (
            this.isPreview(location.pathname) &&
            !this.isPreview(nextProps.location.pathname)
        ) {
            this.props.update('schemeChanged')(false);
        }
        //do not call this.onBubbleChange if isIdRoute is false and willBeIdRoute its true
        //closed bubble when there is a change
        if (location.pathname !== nextProps.location.pathname) {
            setShowSearchThisArea(false);
            if (!isIdRoute && willBeIdRoute) {
                return;
            }
            this.onBubbleChange('closed');
        }

        const newSearch = nextProps.location.search;
        const prevSearch = location.search;
        if (!newSearch || newSearch === prevSearch) return;

        // Map.jsx handles pos, lat, long and zoom changes via handleViewChange
        const newSearchParams = getQueryStringAsObject(newSearch);
        const prevSearchParams = getQueryStringAsObject(prevSearch);
        const qChanged = newSearchParams.q !== prevSearchParams.q;
        const isCollection =
            (location || {}).pathname === Routes.MAP_COLLECTIONS;
        if (!isCollection && qChanged) setShowSearchThisArea(true);
    };

    componentWillUnmount = () => {
        undoOsanoWidgetRemoval();

        // Clear the geolocation watch callback if it was created
        cleanupGeolocation(this.watchGeoId);
        clearInterval(this.intervalo);
        document.removeEventListener('keydown', this.overridePrint, false);
    };

    overridePrint = (e) => {
        const { setPrint, mapPrint, setShowSearchThisArea } = this.props;
        // if already showing the modal, do nothing
        if (mapPrint) {
            setShowSearchThisArea(false);
            return;
        }
        if (e.key === 'p' && (e.metaKey || e.ctrlKey)) {
            setShowSearchThisArea(false);
            e.preventDefault();
            setPrint(true);
        }
    };

    handleWheel = (e) => {
        const { deltaY } = e;

        this.closeContextMenu();

        // don't traverse if we're scrolling in an info bubble
        if (
            isChildOf(
                e.target,
                (el) => el.classList && el.classList.contains('H_ib_body')
            )
        ) {
            return;
        }

        const { mapSelectedDataArgs, mapZoom } = this.props;
        const zoom = -Math.sign(deltaY);
        const nextZoom = mapZoom + zoom;

        // on zoom past cluster, hide it
        if (mapSelectedDataArgs) {
            const { minZoom, maxZoom } = mapSelectedDataArgs || {};
            if ((maxZoom && nextZoom > maxZoom) || nextZoom < minZoom) {
                this.onBubbleChange('closed');
            }
        }
    };

    openContextMenu = (contextMenuEvent, pointId, i, meta = {}) => {
        if (this.isType(['CREATE', 'EDIT'])) {
            const event = (contextMenuEvent || {}).originalEvent || {};
            this.setState({
                contextMenu: {
                    style: { top: event.pageY - 68, left: event.pageX },
                    geocoord: contextMenuEvent.geocoord,
                    pointId,
                    meta,
                    i
                }
            });
        }
    };

    closeContextMenu = () => this.setState({ contextMenu: false });

    onBubbleChange = (state) => {
        if (state === 'closed') {
            this.props.update('selectedData')(null);
            this.props.update('selectedDataArgs')(null);
        }
    };

    onMarkerClick = (marker, data, onMapRide = false) => {
        /* we could prob change data to getZoom boolean attribute ( not related to state )
        to control use case scenarios in which map is not being send to this method*/
        const isArray = Array.isArray(marker);
        const { minZoom, maxZoom } = data || {};
        const args = { minZoom, maxZoom };
        const callAnalytics = (marker) =>
            analyticsPOILocationDetailEvent(dataForAnalytics(marker));

        if (marker && (isArray ? marker.length > 0 : true)) {
            log('onMarkerClick', marker);
            if (isArray) {
                const item = { ...marker[0], multiple: true };
                callAnalytics(item);
            } else {
                callAnalytics(marker);
            }

            const { update, centerPointZoom } = this.props;

            const isRide = isArray
                ? (marker[0] || {}).waypoints
                : marker.waypoints;

            if (onMapRide) {
                const transformedRide = transformRide(marker);
                update('selectedData')(transformedRide);
                update('selectedDataArgs')(args);
            }
            if (isArray && isRide) {
                const transformedRide = marker.map(transformRide);
                update('selectedData')(transformedRide);
                update('selectedDataArgs')(args);
                centerPointZoom(normalizeLatLng(transformedRide[0].position));
            } else if (!isRide) {
                update('selectedData')(marker);
                update('selectedDataArgs')(args);

                const mark = isArray ? marker[0] : marker;
                const position = normalizeLatLng(
                    mark.position ? mark.position : mark
                );

                const { mapBounds, mapZoom } = this.props;
                const options = {
                    padding: {
                        top: isArray ? 210 : 150,
                        left: 150,
                        right: 150,
                        bottom: 0
                    }
                };

                const isCreate = window.location.pathname.includes('/create');
                if (
                    !isCreate &&
                    !isLatLngInBounds(
                        position,
                        { bounds: mapBounds, zoom: mapZoom },
                        options
                    )
                ) {
                    centerPointZoom(position);
                }
            }
        }
    };

    onWaypointClickId = (id, i) => {
        const { waypoints } = this.props.edit_ride;
        const waypoint = !!i
            ? waypoints[i]
            : waypoints.find((e) => e.id === id);
        this.onMarkerClick({ ...waypoint, markerType: 'waypoint' });
    };

    onCenter = () => {
        const { update } = this.props;
        update('centerMyLocation')(true);
    };

    render() {
        const {
            location,
            fetchItems,
            dropdownOpen,
            updateRideFilters,
            updateRidesFiltered,
            ridesFilters,
            rideTags,
            rides,
            selectedRide,
            setImageIndex,
            events,
            dealers,
            navigate,
            createModalShowing,
            toggleCheckboxModal,
            setShowSearchThisArea,
            showOptions,
            setShowOptions,
            fetchPOI,
            userId,
            type
        } = this.props;

        const poisFiltersCheckboxCounts = {
            stateEventsCount: events.length,
            stateDealersCount: dealers.length
        };

        const { contextMenu, showSidepanelFilter, showSidePanel, previewOpen } =
            this.state;
        const { pathname } = location;

        const { mapPrint, showImagesSidebar } = this.props;
        // TODO: add when hiding progress bar is implemented
        const footerHeight = 120; //computedFooterHeight(pathname, map.drawerState);
        const showFooter = onRideView(location.pathname, rideViews, matchPath);

        const footerClass = !showFooter ? 'transform-0px' : `transform-60px`;
        const { isMobile } = this.context;

        // totally not hacky solution
        const scaleBar = document.querySelector('.H_scalebar');
        if (scaleBar) {
            scaleBar.style.transform = `translateY(-${
                !showFooter ? 85 : footerHeight + 25
            }px) translateX(-53px)`;
            if (isMobile) {
                scaleBar.style.transform =
                    'translateY(-15px) translateX(-60px)';
            }
        }

        const modifiedRides = modifiedSelectedRides(rides, userId);

        const itemPaths = [
            Routes.RIDE_CREATE,
            Routes.RIDE_EDIT,
            Routes.RIDE_PREVIEW,
            Routes.RIDE_CREATE_PREVIEW,
            Routes.SHARED_RIDE
        ];

        const isRidePath = (path) =>
            itemPaths.some((p) => matchPath({ path: p }, path));
        const isRideShowing = isRidePath(pathname);

        const hereMapsDefaultTrademark =
            document.getElementsByClassName('H_imprint')[0];
        if (!!hereMapsDefaultTrademark) {
            hereMapsDefaultTrademark.style.display = 'none';
        }

        const pushUrl = (url) => navigate(url);

        const createType = () => {
            switch (type) {
                case 'create':
                    return (
                        <MapCreate
                            {...this.props}
                            isMobile={this.context.isMobile}
                            editable
                            openContextMenu={this.openContextMenu}
                            onMarkerClick={this.onMarkerClick}
                            onWaypointClickId={this.onWaypointClickId}
                            contextMenu={contextMenu}
                            closeContextMenu={this.closeContextMenu}
                        />
                    );
                case 'preview':
                    return (
                        <MapPreview
                            {...this.props}
                            onMarkerClick={this.onMarkerClick}
                        />
                    );

                case 'default_map':
                    return (
                        <MapDefault
                            {...this.props}
                            rides={modifiedRides}
                            onMarkerClick={this.onMarkerClick}
                        />
                    );
                default:
                    console.log('there was a problem with type prop');
                    break;
            }
        };

        return (
            <div className={style.MapListView}>
                {!!selectedRide && showImagesSidebar && (
                    <ImageView
                        onRequestClose={() => this.props.setShowImages(false)}
                        images={selectedRide.photos}
                        setShowImagesSidebar={mapActions.setShowImagesSidebar}
                        setImages={mapActions.setImages}
                        setImageIndex={setImageIndex}
                        openSidebar={() => openSidebar('images')}
                        large={true}
                        vertical={true}
                    />
                )}
                {mapPrint && (
                    <PrintView
                        isOpen={mapPrint}
                        onRequestClose={() => this.props.setPrint(false)}
                        onZoom={this.props.onZoom}
                    />
                )}
                <ErrorBoundary>
                    <Map
                        onViewChange={(shouldFetch) => {
                            if (shouldFetch) {
                                if (this.isType(['MAP', 'RIDE'])) {
                                    fetchItems();
                                }
                            }
                        }}
                        isMobile={isMobile}
                        setShowSearchThisArea={() => setShowSearchThisArea}
                        fetchPOI={fetchPOI}
                        onWheel={this.handleWheel}
                        onContextMenu={this.openContextMenu}
                        offRoad={false}
                        onClick={(e) => {
                            const { location, selectedData, onSelectRide } =
                                this.props;
                            const { pathname } = location;
                            const isMap = pathname.startsWith('/map/');
                            const hasId =
                                (e.target.P || {}).id ||
                                (e.target.P || {}).ride;
                            // If map (not poi) is clicked close dealer, event, ride or other poi
                            if (isMap && !hasId) {
                                if (selectedData) {
                                    this.props.update('selectedData')(null);
                                    this.props.update('selectedDataArgs')(null);
                                }
                            }
                            if (
                                matchPath(
                                    {
                                        path: Routes.MAP_RIDE
                                    },
                                    pathname
                                ) &&
                                !matchPath(
                                    {
                                        path: Routes.RIDE_PREVIEW
                                    },
                                    pathname
                                ) &&
                                !matchPath(
                                    {
                                        path: Routes.RIDE_EDIT
                                    },
                                    pathname
                                )
                            ) {
                                onSelectRide(undefined, this.props.navigate);
                            }
                            const el = document.activeElement;
                            if (el && typeof el.blur === 'function') {
                                el.blur();
                            }
                        }}
                        createModalShowing={createModalShowing}
                        {...this.props}>
                        {createType()}

                        <MapCommon
                            location={location}
                            onMarkerClick={this.onMarkerClick}
                            onBubbleChange={this.onBubbleChange}
                            pushUrl={pushUrl}
                        />
                    </Map>
                </ErrorBoundary>
                {showSidepanelFilter && !isRideShowing && ridesFilters && (
                    <ErrorBoundary>
                        <SidepanelFilter
                            rideTags={rideTags}
                            isOpen={true}
                            onClickNavigation={() => console.log('onClickNav')}
                            selectedNavigation={'rides'}
                            navigationItems={[{ key: 'rides', label: 'rides' }]}
                            onRequestClose={() =>
                                this.setState({
                                    showSidepanelFilter: !showSidepanelFilter
                                })
                            }
                            checkboxGroups={{
                                rides: getRideCheckboxData(rideTags)
                            }}
                            ridesCheckboxCounts={countByKey(
                                modifiedRides,
                                'subType',
                                '',
                                ''
                            )}
                            groupCheckboxCounts={countByKey(
                                modifiedRides,
                                'type'
                            )}
                            poisFiltersCheckboxCounts={
                                poisFiltersCheckboxCounts
                            }
                            isMobile={false}
                            label={'rides'}
                            ridesFilters={ridesFilters}
                            ridesFiltered={modifiedRides}
                            applyFilters={(types) => {
                                updateRideFilters(types);
                                const filteredRides = getFilteredRides(
                                    types,
                                    rides,
                                    userId
                                );
                                updateRidesFiltered(filteredRides);
                                this.setState({
                                    showSidepanelFilter: false
                                });
                            }}
                            setShowSidepanelFilter={() => {
                                this.setState({
                                    showSidepanelFilter: !showSidepanelFilter
                                });
                            }}
                            selectedTypes={[]}
                            style={{
                                overlay: {
                                    width: isMobile ? '100%' : '364px',
                                    paddingTop: '64px',
                                    zIndex: 2
                                }
                            }}
                        />
                    </ErrorBoundary>
                )}
                {!showFooter && !isRideShowing && (
                    <ErrorBoundary>
                        <SidepanelContainer
                            toggleCheckboxModal={toggleCheckboxModal}
                            filteredRides={modifiedRides}
                            filteredTypes={ridesFilters || []}
                            setShowSidepanelFilter={() => {
                                this.setState({
                                    showSidepanelFilter: !showSidepanelFilter
                                });
                            }}
                            setShowSidePanel={(val) => {
                                this.setState({ showSidePanel: val });
                            }}
                            showSidePanel={showSidePanel}
                        />
                    </ErrorBoundary>
                )}
                <div
                    className={style.mapFooter}
                    style={{
                        height: !showFooter ? 0 : '60px'
                    }}>
                    {
                        <HEREMapsLogo
                            className={classNames(
                                style.logo,
                                style[footerClass]
                            )}
                            style={
                                (dropdownOpen && isRideShowing) ||
                                (showSidePanel && !isRideShowing)
                                    ? { left: '386px' }
                                    : { left: '16px' }
                            }
                        />
                    }

                    <UiControls
                        className={classNames(
                            style.uiControls,
                            style[footerClass],
                            {
                                [style.bottomZero]: !showFooter
                            }
                        )}
                        onCenter={this.onCenter}
                        onZoom={this.props.onZoom}
                        onUpdateMap={this.props.update}
                        id={'locate-button'}
                    />
                    {showFooter && (
                        <PreviewFooter
                            {...this.props}
                            toggleCheckboxModal={toggleCheckboxModal}
                            showOptions={showOptions}
                            setShowOptions={setShowOptions}
                            setPreviewOpen={this.setPreviewOpen}
                            previewOpen={previewOpen}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const edit_ride = state.edit_ride.present.ride;
    return {
        rides: state.rides.data,
        dropdownOpen: state.rides.preview.dropdownOpen,
        selectedRide: (state.rides || {}).selected,
        ridesFilters: state.rides.ridesFilters,
        rideTags: state.map.rideTags,
        edit_ride,
        userId: currentUserId(state),
        selectedData: state.map.selectedData,
        mapPrint: state.map.print,
        mapZoom: state.map.zoom,
        mapSelectedDataArgs: state.map.selectedDataArgs,
        mapBounds: state.map.bounds,
        showImagesSidebar: state.map.showImagesSidebar,
        createModalShowing: state.app.isCreateModalShowing,
        events: state.events.data,
        dealers: state.dealers.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        centerPointZoom: (point) => dispatch(mapActions.centerPointZoom(point)),
        fetchItems: () => dispatch(mapActions.fetchItems()),
        selectDealer: (dealer) => dispatch(selectItem(dealer)),
        update: (field) => (value) => dispatch(mapActions.update(field, value)),
        onReceiveLocation: (position, centerLocation) =>
            dispatch(mapActions.didReceiveLocation(position)),
        handleTraverseRoute: (deltaY) =>
            dispatch(ridesActions.handleTraverseRoute(deltaY)),
        onMapHasLoaded: () => dispatch(mapActions.mapHasLoaded()),
        onInitialLoad: (dynamicZoom) => () =>
            dispatch(mapActions.initialLoad(dynamicZoom)),
        setPrint: (value) => dispatch(mapActions.setPrint(value)),
        setImages: (value) => dispatch(mapActions.setImages(value)),
        setShowImages: (value) => dispatch(mapActions.setShowImages(value)),
        setImageIndex: (value) => dispatch(mapActions.setImageIndex(value)),
        onSelectRide: (rideId, navigate) =>
            dispatch(ridesActions.selectRide(rideId, navigate)),
        onSelectDealer: (dealerId) => dispatch(selectDealerId(dealerId)),
        onSelectEvent: (eventId) => dispatch(selectEventId(eventId)),
        resetScrub: () => resetScrub(),
        setShowSearchThisArea: (value) =>
            dispatch(mapActions.setShowSearchThisArea(value)),
        onZoom: (amount = 1) => dispatch(mapActions.zoom(amount)),
        centerMyLocation: () => dispatch(mapActions.centerMyLocation()),
        updateRidesFiltered: (value) =>
            dispatch(ridesActions.update('ridesFiltered', value)),
        updateRideFilters: (value) =>
            dispatch(ridesActions.update('ridesFilters', value)),
        fetchPOI: (coords) => {
            const WAIT_TIME = 400;
            setTimeout(() => {
                dispatch(fetchPOI(coords));
            }, WAIT_TIME);
        }
    };
};

MapListView.contextType = AppContext;

const container = connect(mapStateToProps, mapDispatchToProps)(MapListView);

export default withRouter(container);
